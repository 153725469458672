

























import { Component, Vue } from "vue-property-decorator";
import JSCharting from "jscharting-vue";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";
import { dispatchMARCharts } from "@/store/admin/actions";
import _ from "lodash";

@Component({
  components: {
    JSCharting,
  },
})
export default class QB extends Vue {
  public valid = false;
  public refreshToken = "";
  public revenue_customers = { chartOptions: {} };

  public async mounted() {
    let chart_data = await dispatchMARCharts(this.$store);
    console.log(chart_data);
    console.log(Object.keys(chart_data));
    this.revenue_customers = {
      chartOptions: {
        type: "column",
        xAxis: { label_text: "Month", line_width: 0 },
        title_label_text: "Horizon Fiber Sales",
        defaultAxis: { line_width: 2 },
        yAxis: [
          {
            id: "leftAxis",
            //defaultTick_label_color: colors[0],
            //line_color: colors[0],
            label_text: "Revenue",
            formatString: "c0",
          },
          {
            id: "rightAxis",
            //defaultTick_label_color: colors[3],
            //line_color: colors[3],
            label_text: "Customers",
            orientation: "right",
          },
        ],
        series: [
          {
            name: "Receipts",
            id: "s1",
            yAxis: "leftAxis",
            defaultPoint: {
              //color: "blue",
              label: { visible: true },
            },
            points: chart_data.data.receipts_by_month,
          },
          {
            name: "Customers",
            //id: "s2",
            yAxis: "rightAxis",
            defaultPoint: {
              //color: "green",
              label: { visible: true },
            },
            points: chart_data.data.customers_by_month,
          },
        ],
      },
    };
  }
}
